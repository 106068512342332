import React, {Component, } from 'react';

class ManualsAndPoliciesDashboard extends Component {
    render() {
        return (
            <div>
                <style>
                {`            
                    .ManualsAndPoliciesDashboarddWrapper {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: left;
                        height: 100%;
                        width: 100%;
                        padding: 50px 50px;
                        font-size: 20px;
                    }
                `}
                </style>
                <div class='ManualsAndPoliciesDashboarddWrapper'>
                    <p>For Compliance Manuals and Company Policies, please visit 192.168.200.37 using the application "Remote Desktop Connection".</p>
                    <p>User ID: your desktop login ID</p>
                    <p>The password is provided on your onboarding day. For enquiries, please contact IT Team (Contact Person: Xie Lei)</p>
                    <p></p>
                    <p>若您需要查阅《合规手册》或公司的其他政策，请开启“Remote Desktop Connection”程式，输入地址192.168.200.37。</p>
                    <p>User ID：登入电脑的ID</p>
                    <p>入职当天已提供密码。若有任何疑问，请联络IT组。（联络人: 谢磊)</p>
                </div>
            </div>
        );
    };
}

export default ManualsAndPoliciesDashboard;
